interface IBalanceProps {
  src: string;
  alt: string;
  userCoins: number;
  imgClass?: string;
  textClass?: string;
}

const Balance = ({src, alt, userCoins, imgClass, textClass}: IBalanceProps) => {

  function formatNumberString(str: string) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <>
      <img src={src} alt={alt} className={`${imgClass}`} />
      <span className={`${textClass}`}>{formatNumberString(Math.round(Number(userCoins)).toString())}</span>
    </>
  )
};

export default Balance;
