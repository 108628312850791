import React, { useState } from "react";
import { IUserModel } from "../../../types/types";
import { useTranslation } from "react-i18next";
import ImageSlider from "../ImageSlider/ImageSlider";
import { genderImages, maleGenderImages, femaleGenderImages, roleImages } from "../../../constants/raceImages";
import { setUserCharacterApi } from "../../../api/apiFunctions";

interface CharacterPickerProps {
  setUser: (user: IUserModel) => void;
  setIsCharacterChosen: (isCharacterChosen: boolean) => void;
}

const CharacterPicker: React.FC<CharacterPickerProps> = ({
  setUser, setIsCharacterChosen
}: CharacterPickerProps) => {
  const [t] = useTranslation();
  const [genderIndex, setGenderIndex] = useState(0);
  const [raceIndex, setRaceIndex] = useState(0);

  const handleSelect = () => {
    setUserCharacterApi({
      race: roleImages[raceIndex].value,
      gender: genderImages[genderIndex].value,
    }).then(userModel => {
      setUser(userModel);
      setIsCharacterChosen(true);
    });
  };


  return (
    <div className={"flex flex-col h-dvh gap-4"}>
      <h1 className={"text-center text-3xl font-bold my-4"}>{t(("app.character.pickCharacter"))}</h1>
      <div className={"flex overflow-hidden"}>
        <ImageSlider
          images={genderImages}
          imageSize={"150px"}
          imageIndex={genderIndex}
          setImageIndex={setGenderIndex}
        />
        <ImageSlider
          images={roleImages}
          imageSize={"150px"}
          imageIndex={raceIndex}
          setImageIndex={setRaceIndex}
        />
      </div>
      <div className={"my-10 px-4 w-full"}>
        <button
          onClick={() => {
            handleSelect();
          }}
          className={"w-full bg-green-950 rounded-2xl text-white font-semibold p-4"}
        >
          {t('app.components.select')}
        </button>
      </div>
    </div>
  );
};

export default CharacterPicker;
