import { coinsToNextLevel } from "../constants/coinsToNextLevel";

const formatAndRoundPrice = (number: number): string => {
  if (number > 1000) {
    number = Math.round(number / 10) * 10;
  }
  const length = number.toString().length;
  const unitIndex = Math.floor((length - 1) / 3);
  const scaledNumber = number / Math.pow(1000, unitIndex);
  const formattedNumber = (Math.round(scaledNumber * 10) / 10).toFixed(1);

  return parseFloat(formattedNumber) + 'k'.repeat(unitIndex);
}

const getNextLevelCoinsAmount = (level: number) => {
  if(coinsToNextLevel && coinsToNextLevel.length > 0 && coinsToNextLevel.length > level){
    return coinsToNextLevel[level];
  }
  return NaN;
}

export { formatAndRoundPrice, getNextLevelCoinsAmount };
