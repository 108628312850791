import {
  ICardModel,
  ICardSectionModel,
  IUserBoostCard,
  IUserBoostCardSection
} from "../types/types";

export const mapCardModelToUserBoostCard = (
  cardModel: ICardModel
): IUserBoostCard => {
  return {
    id: cardModel.id,
    sectionId: cardModel.sectionId,
    translationKey: cardModel.translationKey,
    level: 0,
    price: cardModel.basePrice,
    boost: cardModel.baseBoost,
    priceMultiplier: cardModel.priceMultiplier,
    basePriceMultiplier: cardModel.priceMultiplierBase,
    boostMultiplier: cardModel.boostMultiplier,
    requirement: null,
    maxLevel: cardModel.maxLevel,
  }
}

export const mapCardModelsToUserBoostCards = (
  cardModels: ICardModel[]
): IUserBoostCard[] => {
  return cardModels.map((card) => mapCardModelToUserBoostCard(card));
}

export const mapBoostCardSectionDTOToUserBoostCardSection = (
  cardSectionModel: ICardSectionModel
): IUserBoostCardSection => {
  return {
    id: cardSectionModel.id,
    ordering: cardSectionModel.ordering,
    translationKey: cardSectionModel.translationKey.toLowerCase(),
    cards: [],
    requirement: null,
  }
}

export const mapBoostCardSectionsDTOToUserBoostCardSections = (
  boostCardSectionModels: ICardSectionModel[]
): IUserBoostCardSection[] => {
  return boostCardSectionModels.map((section) => mapBoostCardSectionDTOToUserBoostCardSection(section));
}
