import { ReactNode } from "react";
import dailyBonus from "../components/_components/DailyBonus/DailyBonus";

export interface IUserModel {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  languageCode: string;
  photoUrl: string;
  gender: string;
  race: string;
  referredBy: number | null;
  friendsIds: number[];
  startedTasksIds: number[];
  finishedTasksIds: number[];
  coins: number;
  level: number;
  miningPower: number;
  miningStartPower: number | null;
  miningTime: number;
  miningStart: string | null;
  miningFinish: string | null;
  lastLogin: number;
  lastBonusClaimed: string | null;
  lastBonusClaimedId: number | null;
}

export interface IDailyBonusModel {
  id: number;
  bonus: number;
}

export interface ICardModel {
  id: number;
  sectionId: number;
  translationKey: string;
  baseBoost: number;
  basePrice: number;
  boostMultiplier: number;
  maxLevel: number;
  priceMultiplier: number;
  priceMultiplierBase: number;
}

export interface ICardRequirementModel {
  cardId: number | null;
  sectionId: number | null;
  requirementOwnerId: number;
  translationKey: string;
  type: string;
  cardLevel: number | null;
}

export interface ICardSectionModel {
  id: number;
  ordering: number;
  translationKey: string;
}

export interface IReceiptModel {
  id: number;
  userId: number;
  cardId: number;
  sectionId: number;
  boost: number;
  nextLevelBoost: number;
  totalCardBoost: number;
  price: number;
  nextLevelPrice: number;
  level: number;
  timestamp: number;
}

export interface ITaskDTO {
  id: number;
  action: string;
  header: string;
  level: number;
  payload: string;
  reward: number;
  sectionTranslationKey: string;
  type: string;
}

export interface IRequireable {
  id: number;
  requirement: ICardRequirementModel | null;
}

export interface IUserBoostCardSection extends IRequireable {
  ordering: number;
  translationKey: string;
  cards: IUserBoostCard[];
}

export interface IUserBoostCard extends IRequireable {
  sectionId: number;
  translationKey: string;
  level: number;
  price: number;
  boost: number;
  priceMultiplier: number;
  basePriceMultiplier: number;
  boostMultiplier: number;
  maxLevel: number;
}

export enum RequirementType {
  CARD = "CARD",
  TASK = "TASK",
}

export enum ActionType {
  SUBSCRIBE = 'SUBSCRIBE',
  WATCH = 'WATCH',
  JOIN = 'JOIN',
  CONNECT = 'CONNECT',
  MINE = 'MINE',
  INVITE = 'INVITE',
  REDIRECT = 'REDIRECT'
}

export enum TaskType {
  INSTAGRAM = 'INSTAGRAM',
  TELEGRAM = 'TELEGRAM',
  TIKTOK = 'TIKTOK',
  X = 'X',
  YOUTUBE = 'YOUTUBE',
  PARTNER = 'PARTNER'
}

export enum Section {
  PROMO = 'PROMO',
  TASKS = 'TASKS',
  SOCIALS = 'SOCIALS'
}

export interface IContext {
  user: IUserModel;
  setUser: (user: IUserModel) => void;
  userBoostCardSections: IUserBoostCardSection[];
  addReceiptsToReceiptStates: (receipt: IReceiptModel[]) => void;
  isMiningStarted: boolean;
  isMiningFinished: boolean;
  coins: number;
  handleMining: () => void;
  tasks: ITaskDTO[];
  dailyBonusModels: IDailyBonusModel[];
  claimBonus: (dailyBonusModel: IDailyBonusModel) => Promise<void>;
  timeToUnlockBonusButton: string | null;
  setTimeToUnlockBonusButton: (text: string | null) => void;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum Race {
  DARK_ELF = "DARK_ELF",
  GNOME = "GNOME",
  HUMAN = "HUMAN",
  LIGHT_ELF = "LIGHT_ELF",
  ORC = "ORC"
}

export interface UserSafeModel {
  id: number;
  level: number;
  firstName: string;
  lastName: string;
  username: string;
  photoUrl: string;
  race: string;
  miningPower: number;
  coins: number;
}

export interface InitialDataResponse {
  user: IUserModel;
  dailyBonus: IDailyBonusModel[];
  cardSections: ICardSectionModel[];
  requirements: ICardRequirementModel[];
  cards: ICardModel[];
  tasks: ITaskDTO[];
  receiptModels: IReceiptModel[];
}

export interface FinishTaskRequest {
  taskId: number;
}

export interface StartTaskRequest {
  taskId: number;
}

export interface GetFriendsResponse {
  friends: UserSafeModel[];
}

export interface GetStatsResponse {
  totalTopWorldUsers: number;
  topWorldUserModels: UserSafeModel[];
  totalTopInLevelUsers: number;
  topInLevelUserModels: UserSafeModel[];
}

export interface PurchaseCardRequest {
  cardId: number;
}

export interface SetCharacterRequest {
  race: string;
  gender: string;
}
